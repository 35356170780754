import styled from "../../../../../../styles/module/transparency/sacopLicitacaoList.module.css";
import { corIcones } from "../../../../../../services/variables";
import ButtonTypeHidden from "./../../../../others/utils/ButtonTypeHidden";

export default function CardSacop(props) {
  const data = props.data;

  return (
    <section className={styled.grupCardSacopInfor}>
      <div className={styled.card}>
        <div className={styled.cardTop}>
          <p>
            <b>Objeto:</b>
          </p>
          <p>
            {data.OBJETO !== "" && (
              <a
                href={`/licitacoes-e-contratos/detalhes/${data.ID}`}
                title="Mais Detalhes"
                className={styled.linkCard}
              >
                {data.OBJETO}
              </a>
            )}
            {/* {data. !== "" && <>{data.}</>} */}
          </p>
        </div>
        <div className={styled.cardMiddle}>
          <div className={styled.grupInfo}>
            <div className={styled.iconsInfo}>
              {/* <img src="/icons/sacop/database-fill-gear.svg" className={styled.icons} alt="ícone-processo"/> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                className={styled.iconsCard}
                viewBox="0 0 16 16"
              >
                <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1Z" />
                <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7Zm6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002Zm-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905Zm3.631-4.538c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
              </svg>
            </div>
            <div>
              {data.EXERCICIO !== "" && (
                <div className={styled.textInfo}>
                  <p className={styled.textField}>
                    <b>Exercício:</b>
                  </p>
                  <p className={styled.textComplement}> {data.EXERCICIO}</p>
                </div>
              )}
              {data.NUMERO_PROCESSO !== "" && (
                <div className={styled.textInfo}>
                  <p className={styled.textField}>
                    <b>Número Processo:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.NUMERO_PROCESSO}
                  </p>
                </div>
              )}
              {data.NUMERO_INSTRUMENTO !== "" && (
                <div className={styled.textInfo}>
                  <p className={styled.textField}>
                    <b>Número Instrumento:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.NUMERO_INSTRUMENTO}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styled.grupInfo}>
            <div className={styled.iconsInfo}>
              {data.DATA_ABERTURA !== "" && (
                <>
                  {data.DATA_PUBLICACAO == "" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      className={styled.iconsCard}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  )}
                </>
              )}
              {data.DATA_ABERTURA == "" && (
                <>
                  {data.DATA_PUBLICACAO !== "" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      className={styled.iconsCard}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  )}
                </>
              )}
              {data.DATA_ABERTURA !== "" && (
                <>
                  {data.DATA_PUBLICACAO !== "" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      className={styled.iconsCard}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  )}
                </>
              )}
            </div>
            <div>
              {data.DATA_ABERTURA !== "" && (
                <div className={styled.textInfo}>
                  <p className={styled.textField}>
                    <b>Data Abertura:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.DATA_ABERTURA != undefined ? (
                      <>{data.DATA_ABERTURA}</>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              )}
              {data.DATA_PUBLICACAO !== "" && (
                <div className={styled.textInfo}>
                  <p className={styled.textField}>
                    <b>Data Publicação:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.DATA_PUBLICACAO != undefined ? (
                      <>{data.DATA_PUBLICACAO}</>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styled.grupInfo}>
            <div className={styled.iconsInfo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                className={styled.iconsCard}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z"
                />
              </svg>
              {/* <img src="/icons/sacop/clipboard-pulse.svg" className={styled.icons} alt="ícone-processo"/> */}
            </div>
            <div>
              {data.UNIDADE_NOME !== "" && (
                <div className={styled.textInfoMax}>
                  <p className={styled.textField}>
                    <b>Unidade:</b>
                  </p>
                  <p className={styled.textComplement}>{data.UNIDADE_NOME}</p>
                </div>
              )}
              {data.MODALIDADE_NOME !== "" && (
                <div className={styled.textInfoMax}>
                  <p className={styled.textField}>
                    <b>Modalidade:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.MODALIDADE_NOME}
                  </p>
                </div>
              )}
              {data.TIPO_LICITACAO_NOME !== "" && (
                <div className={styled.textInfoMax}>
                  <p className={styled.textField}>
                    <b>Tipo:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.TIPO_LICITACAO_NOME}
                  </p>
                </div>
              )}
              {data.TIPO_OBJETO_NOME !== "" && (
                <div className={styled.textInfoMax}>
                  <p className={styled.textField}>
                    <b>Tipo Objeto:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.TIPO_OBJETO_NOME}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styled.grupInfo}>
            <div className={styled.iconsInfo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                className={styled.iconsCard}
                viewBox="0 0 16 16"
              >
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
              </svg>
              {/* <img src="/icons/sacop/card-checklist.svg" className={styled.icons} alt="ícone-status"/> */}
            </div>
            <div>
              {data.STATUS_LICITACAO_NOME !== "" && (
                <div className={styled.textInfoMax}>
                  <p className={styled.textField}>
                    <b>Status:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.STATUS_LICITACAO_NOME}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styled.grupInfo}>
            <div className={styled.iconsInfo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                className={styled.iconsCard}
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                />
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
              </svg>
              {/* <img src="/icons/sacop/cash-coin.svg" className={styled.icons} alt="ícone-valor" /> */}
            </div>
            <div>
              {data.VALOR_TOTAL_DESPESA !== "" && (
                <div className={styled.textInfo}>
                  <p className={styled.textField}>
                    <b>Valor Total Despesa:</b>
                  </p>
                  <p className={styled.textComplement}>
                    {data.VALOR_TOTAL_DESPESA != undefined ? (
                      <>{data.VALOR_TOTAL_DESPESA}</>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              )}

              {data.DOC_RESULTADO_CERTAME != undefined ? (
                <>
                  <div className={styled.textInfo}>
                    <p className={styled.textField}>
                      <b></b>
                    </p>
                    <p className={styled.textComplement}></p>
                  </div>
                  <div className={styled.textInfo}>
                    <p className={styled.textField}>
                      <b></b>
                    </p>
                    <p className={styled.textComplement}>
                      <ButtonTypeHidden
                        link={
                          "http://www.transparenciadministrativa.com.br/portalimagem/" +
                          data.CNPJ.replace("/", "")
                            .replace(".", "")
                            .replace(".", "")
                            .replace("-", "") +
                          "/" +
                          data.DOC_RESULTADO_CERTAME
                        }
                        targetBlank={true}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          fill={corIcones}
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </ButtonTypeHidden>
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <a
            href={`/licitacoes-e-contratos/detalhes/${data.ID}`}
            className={styled.btnPrimario}
          >
            Detalhar
          </a>
        </div>

        <div
          className={
            data.STATUS_LICITACAO_NOME == "EM INTENCAO DE LICITAR"
              ? styled.cardfooterDestak
              : styled.cardfooter
          }
        >
          <p>
            <b>Última Movimentação:</b>
          </p>
          <p>{data.DATA_ENVIO != undefined ? <>{data.DATA_ENVIO}</> : <></>}</p>
        </div>
      </div>
    </section>
  );
}
